import React from "react"
import {Folder} from "react-feather"
import styled from "styled-components"
import {graphql} from "gatsby"
import Layout from "../components/layout2"
import SEO from "../components/seo"
import {Row, Col} from "reactstrap"
import SinglePost from "../components/SinglePost"

const StyledWrapper = styled.div`
  &.table-of-contents {
    background: #f5f8fa;
    padding: 20px;
    width: 100%;
    height: 350px;
    overflow: scroll;
    border-right: 1px solid #e5e5e5;
    .table-of-contents__header {
      display: flex;
      align-items: flex-end;
      margin: 0 0 5px 0;
      padding: 0;
      color: ${props => props.theme.tagColor};
    }

    h4 {
      margin: 0;
      padding: 0;
    }

    ul,
    ol {
      list-style-type: none;
      &:first-child {
        padding: 0;
      }
      padding-left: 20px;

      li {
        line-height: 1.5;

        p {
          display: inline-block;
          margin: 0;
        }

        a {
          display: inline-block;
          color: ${props => props.theme.tagColor};

          &:hover,
          &:focus {
            text-decoration: underline;
            color: ${props => props.theme.tagColor};
          }
        }
      }
    }
  }
`

const SinglePostPage = props => {
  const {data} = props
  const post = data.markdownRemark.frontmatter

  return (
    <Layout>
      <SEO
        title={post.title}
        pathname={props.location.pathname}
        imagePath={post.image?.childImageSharp?.fluid?.src}
        type="article"
        description={data.markdownRemark.excerpt}
        keywords={post?.tags}
        isBlogPost
      />
      <Row>
        <Col md="3">
          <div className="sticky-top" style={{top: "60px"}}>
            <StyledWrapper className="table-of-contents">
              <div className="table-of-contents__header">
                <Folder size="24" />
                <h4>目錄</h4>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.markdownRemark.tableOfContents,
                }}
              />
            </StyledWrapper>
          </div>
        </Col>
        <Col md="9">
          <div className="global_base-post-section">
            <SinglePost
              key={data.markdownRemark.id}
              title={post.title}
              date={post.date}
              author={post.author}
              slug={data.markdownRemark?.fields?.slug}
              fluid={post?.image?.childImageSharp?.fluid}
              tags={post.tags}
              html={data.markdownRemark.html}
              shareLink={`${data.site.siteMetadata.url}${props.location.pathname}`}
            />
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export const postQuery = graphql`
  query blogPostBySlug($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      rawMarkdownBody
      tableOfContents(maxDepth: 3)
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD ddd")
        author
        tags
      }
      fields {
        slug
      }
      excerpt
    }
    site {
      siteMetadata {
        title
        description
        author
        url
      }
    }
  }
`

export default SinglePostPage
