import styled from "styled-components"

const StyledContent = styled.div`
&.markdown-section {
  h1,
  h2,
  h4,
  strong {
    color: #2c3e50;
    font-weight: 700;
  }
  a {
    color: #204B57;
    /* color: #466624; */
    font-weight: 600;
    text-decoration-line: underline;
  }
  h1 {
    font-size: 2rem;
    margin: 0 0 1rem;
    color: #350608;
  }
  h2 {
    font-size: 1.75rem;
    margin: 45px 0 0.8rem;
  }
  h3 {
    font-size: 1.5rem;
    margin: 40px 0 0.6rem;
    color: #660000;
  }
  h4 {
    font-size: 1.25rem;
    color: #FCAA67;
  }
  h5 {
    font-size: 1rem;
    color: #736666;
  }
  h6 {
    color: #777;
    font-size: 1rem;
  }
  figure,
  p {
    margin: 1.2em 0;
    /* color: #0e2646; */
    color: #555;
  }
  p,
  ul,
  ol {
    line-height: 1.6rem;
    word-spacing: 0.05rem;
  }
  ul,
  ol {
    padding-left: 1.5rem;
  }
  blockquote {
    border-left: 4px solid #42b983;
    color: #858585;
    margin: 2em 0;
    padding-left: 20px;
  }
  blockquote p {
    font-weight: 600;
    margin-left: 0;
  }
  iframe {
    margin: 1em 0;
  }
  em {
    color: #7f8c8d;
  }
}
`


export default StyledContent