import React from "react"
import {StaticQuery, graphql} from "gatsby"
import {
  FacebookShareButton,
  LineShareButton,
  FacebookIcon,
  LineIcon,
} from "react-share"
import Img from "gatsby-image"
import {Share2} from "react-feather"
import styled from "styled-components"

import PostBrief from "../components/Post/PostBrief"
import Title from "../components/Title"
import StyledContent from "./StyledContent"

const StyledWrapper = styled.div`
  &.single-post {
    color: ${props => props.theme.tagColor};

    .single-post__share {
      &__title {
        line-height: 2.5;
        display: inline-block;
        margin-right: 10px;
      }
      .react-share__ShareButton {
        margin-right: 10px;
      }
    }
  }
`

const defaultPostImgQuery = graphql`
  query {
    defaultPostImg: file(relativePath: {eq: "default.png"}) {
      childImageSharp {
        fluid(maxWidth: 770) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const PostImg = props => {
  return (
    <StaticQuery
      query={defaultPostImgQuery}
      render={data => {
        const {fluid} = props
        let fluidInfo
        if (fluid) {
          fluidInfo = {...fluid, aspectRatio: 16 / 9}
        } else {
          fluidInfo = {
            ...data.defaultPostImg.childImageSharp.fluid,
            aspectRatio: 16 / 9,
          }
        }
        // return (
        //   <Img
        //     className="card-image-top"
        //     fluid={fluidInfo}
        //     imgStyle={
        //       {
        //         // maxHeight: "450px",
        //       }
        //     }
        //   />
        // )
        return <div />
      }}
    />
  )
}

const SinglePost = ({title, date, author, fluid, tags, html, shareLink}) => {
  return (
    <StyledWrapper className="single-post">
      <Title>{title}</Title>
      <PostImg fluid={fluid} />
      <PostBrief author={author} date={date} tags={tags} />
      <hr />
      <StyledContent
        className="markdown-section"
        dangerouslySetInnerHTML={{__html: html}}
      />
      <hr />
      <div className="single-post__share">
        <span className="single-post__share__title">
          <Share2 size={24} />
          <strong>Share</strong>
        </span>
        <FacebookShareButton url={shareLink}>
          <FacebookIcon size={40}></FacebookIcon>
        </FacebookShareButton>
        <LineShareButton url={shareLink}>
          <LineIcon size={40}></LineIcon>
        </LineShareButton>
      </div>
    </StyledWrapper>
  )
}

export default SinglePost
